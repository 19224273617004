import 'virtual-keyboard';
import { positionSearchForm, fixedSearchForm } from './search-form.js';
$(document).ready(function () {
  $('#input-domain').keyboard({
    autoAccept: true,
    autoAcceptOnEsc: true,
    position: {
      of: null,
      my: 'right top',
      at: 'center top'
    },
    usePreview: false,
    openOn: null,
    layout: 'custom',
    display: {
      'meta1': 'Latin:Latin',
      'meta2': 'Cyrillic:Cyrillic',
      'meta3': 'Greek:Greek',
      'accept': '&times;'
    },
    customLayout: {
      'meta1': ['{meta1} {meta2} {meta3} {b}', '{sp:1}', '0 1 2 3 4 5 6 7 8 9 -', 'a b c d e f g h i j k l m n', 'o p q r s t u v w x y z', '{sp:1}', "\xE0 \xE1 \xE2 \xE3 \xE4 \xE5 \xE6 \xE7 \xE8 \xE9 \xEA \xEB", "\xEC \xED \xEE \xEF \xF0 \xF1 \xF2 \xF3 \xF4 \xF5 \xF6 \xF8", "\xF9 \xFA \xFB \xFC \xFD \xFE \xFF \xDF", '{sp:1}', "\u0101 \u0103 \u0105 \u0107 \u0109 \u010B \u010D \u010F \u0111 \u0113 \u0115 \u0117", "\u0119 \u011B \u011D \u011F \u0121 \u0123 \u0125 \u0127 \u0129 \u012B \u012D \u012F", "\u0131 \u0135 \u0137 \u013A \u013C \u013E \u0140 \u0142 \u0144 \u0146 \u0148 \u0149", "\u014B \u014D \u014F \u0151 \u0153 \u0155 \u0157 \u0159 \u015B \u015D \u0161 \u0165", "\u0167 \u0169 \u016B \u016D \u016F \u0171 \u0173 \u0175 \u0177 \u017A \u017C \u017E", '{sp:1}', "\u0219 \u021B", '{sp:1}'],
      'meta2': ['{meta1} {meta2} {meta3} {b}', '{sp:1}', "\u0430 \u0431 \u0432 \u0433 \u0434 \u0435 \u0436 \u0437 \u0438 \u0439 \u043A \u043B", "\u043C \u043D \u043E \u043F \u0440 \u0441 \u0442 \u0443 \u0444 \u0445 \u0446 \u0447", "\u0448 \u0449 \u044A \u044B \u044C \u044D \u044E \u044F"],
      'meta3': ['{meta1} {meta2} {meta3} {b}', '{sp:1}', "\u0390 \u03AC \u03AD \u03AE \u03AF \u03B0 \u03B1 \u03B2 \u03B3 \u03B4 \u03B5 \u03B6", "\u03B7 \u03B8 \u03B9 \u03BA \u03BB \u03BC \u03BD \u03BE \u03BF \u03C0 \u03C1 \u03C2", "\u03C3 \u03C4 \u03C5 \u03C6 \u03C7 \u03C8 \u03C9 \u03CA \u03CB \u03CC \u03CD \u03CE", '{sp:1}', "\u1F00 \u1F01 \u1F02 \u1F03 \u1F04 \u1F05 \u1F06 \u1F07 \u1F10 \u1F11 \u1F12", "\u1F13 \u1F14 \u1F15 \u1F20 \u1F21 \u1F22 \u1F23 \u1F24 \u1F25 \u1F26 \u1F27", "\u1F30 \u1F31 \u1F32 \u1F33 \u1F34 \u1F35 \u1F36 \u1F37 \u1F40 \u1F41 \u1F42", "\u1F43 \u1F44 \u1F45 \u1F50 \u1F51 \u1F52 \u1F53 \u1F54 \u1F55 \u1F56 \u1F57", "\u1F60 \u1F61 \u1F62 \u1F63 \u1F64 \u1F65 \u1F66 \u1F67 \u1F70 \u1F71 \u1F72", "\u1F73 \u1F74 \u1F75 \u1F76 \u1F77 \u1F78 \u1F79 \u1F7A \u1F7B \u1F7C \u1F7D", "\u1F80 \u1F81 \u1F82 \u1F83 \u1F84 \u1F85 \u1F86 \u1F87 \u1F90 \u1F91 \u1F92", "\u1F93 \u1F94 \u1F95 \u1F96 \u1F97 \u1FA0 \u1FA1 \u1FA2 \u1FA3 \u1FA4 \u1FA5", "\u1FA6 \u1FA7 \u1FB0 \u1FB1 \u1FB2 \u1FB3 \u1FB4 \u1FB6 \u1FB7 \u1FC2 \u1FC3", "\u1FC4 \u1FC6 \u1FC7 \u1FD0 \u1FD1 \u1FD2 \u1FD3 \u1FD6 \u1FD7 \u1FE0 \u1FE1", "\u1FE2 \u1FE3 \u1FE4 \u1FE5 \u1FE6 \u1FE7 \u1FF2 \u1FF3 \u1FF4 \u1FF6 \u1FF7"]
    },
    // arguments are required for function to work properly
    // eslint-disable-next-line no-unused-vars
    beforeVisible: function beforeVisible(e, keyboard, el) {
      fixedSearchForm(true);
      $('#section-banner .overlay').removeClass('opacity-75');
      $('html').addClass('overflow-hidden');
      keyboard.showKeySet('meta1');
    },
    // arguments are required for function to work properly
    // eslint-disable-next-line no-unused-vars
    visible: function visible(e, keyboard, el) {
      $('<div class="ui-keyboard-backdrop"></div>').prependTo(keyboard.$keyboard);
      $(".ui-keyboard-backdrop").one('click', function () {
        keyboard.close(true);
      });
    },
    // arguments are required for function to work properly
    // eslint-disable-next-line no-unused-vars
    beforeClose: function beforeClose(e, keyboard, el, accepted) {
      $('body > *:not(header, .ui-keyboard)').removeClass('blur');
      $('html').removeClass('overflow-hidden');
      $('#section-banner .overlay').removeClass('opacity-75');
      positionSearchForm();
    }
  });
  $('#button-virtual-keyboard').on('mousedown', function (e) {
    e.preventDefault();
    e.stopPropagation();
  });
  $('#button-virtual-keyboard').on('click', function (e) {
    e.preventDefault();
    e.stopPropagation();
    var keyboard = $('#input-domain').getkeyboard();

    if (keyboard.isOpen) {
      keyboard.close(true);
    } else {
      keyboard.reveal();
    }
  });
});