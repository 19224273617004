export var fixedSearchForm = function fixedSearchForm() {
  var fromKeyBoard = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
  // function to position everything when keyboard is visible
  var $header = $('#header');
  var $tldList = $('#tld-list');
  var $searchForm = $('#search-form');
  $header.removeClass('position-absolute').addClass('fixed-top bg-darkblue shadow-bottom');
  $tldList.addClass('d-none');

  if (fromKeyBoard) {
    $searchForm.css("z-index", 2);
    $searchForm.css("top", "100px");
  } else {
    $searchForm.css("z-index", 9999999);
    $searchForm.css("top", window.innerWidth >= 700 ? "15px" : "75px");
  }

  $searchForm.css("left", "25%");
  $searchForm.css("right", "25%");
  $searchForm.addClass('w-50 mb-5 position-fixed');
};
export var absoluteSearchForm = function absoluteSearchForm() {
  // function to position everything when keyboard is not visible
  var $header = $('#header');
  var $tldList = $('#tld-list');
  var $searchForm = $('#search-form');
  $header.removeClass('fixed-top bg-darkblue shadow-bottom').addClass('position-absolute');
  $tldList.removeClass('d-none');
  $searchForm.css("z-index", "");
  $searchForm.css("top", "");
  $searchForm.css("left", "");
  $searchForm.css("right", "");
  $searchForm.removeClass('w-50 mb-5 position-fixed');
};
export var positionSearchForm = function positionSearchForm() {
  var $header = $('#header');
  var scrollTrigger = 240;
  var keyboard = $('#input-domain').getkeyboard();

  if (window.scrollY > scrollTrigger) {
    fixedSearchForm();
  } else if (window.scrollY < scrollTrigger && ($header.hasClass('fixed-top') || keyboard.isVisible())) {
    absoluteSearchForm();
  }
};